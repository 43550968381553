:root {
	--white: #fff;

	--green-400: #07cfc0;

	--red-300: #ef6e64;
	--red-500: #f50057;

	--purple-500: #8774FF;
	--purple-900: #3f51b5;

	--yellow-500 : #FCDE17;

	--gray-100: #909090;
	--gray-300: #606060;
	--gray-500: #545454;
	--gray-700: #424242;
	--gray-900: #303030;
}


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
	font-family: "Mulish", sans-serif;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

html {
	@media (max-width: 1080px) {
		font-size: 93.75%;
	}
	@media (max-width: 720px) {
		font-size: 87.5%;
	}
}

button {
	cursor: pointer;
}

a:visited {
	background-color: inherit;
}